<template>
  <Layout>
    <div class="app-prize-order-list">
      <a-page-header title="中奖记录" />

      <div class="tool">
        <a-form layout="inline" :form="form" @submit="handleSearch">
          <a-form-item>
            <a-input
              v-decorator="['nickName']"
              :maxLength="20"
              placeholder="昵称"
            />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="avatarUrl" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
        </a-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const PrizeSvc = require("@/service/prize");

export default {
  name: "PrizeOrderList",

  data() {
    return {
      form: this.$form.createForm(this, { name: "form_search" }),

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false
    };
  },

  computed: {
    columns() {
      const { filters } = this;

      return [
        {
          title: "头像",
          align: "center",
          dataIndex: "avatarUrl",
          key: "avatarUrl",
          width: 160,
          scopedSlots: { customRender: "avatarUrl" }
        },
        {
          title: "昵称",
          dataIndex: "nickName",
          key: "nickName",
          width: 160,
          filteredValue: filters.nickName || null
        },
        {
          title: "奖品",
          dataIndex: "prizeName",
          key: "prizeName"
        },
        {
          title: "中奖时间",
          align: "center",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 160,
          scopedSlots: { customRender: "createdAt" }
        }
      ];
    }
  },

  methods: {
    async handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { nickName } = values;

      nickName = nickName || "";
      this.filters = { ...this.filters, nickName };

      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      if (this.pinId) {
        params.pinId = this.pinId;
      }

      try {
        const data = await PrizeSvc.queryOrder({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    let { prizeId } = this.$route.query || {};
    if (prizeId) {
      this.prizeId = prizeId;
    }

    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-prize-order-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }
}
</style>
